import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import JwtService from '@/core/services/jwt.service'
import Swal from 'sweetalert2'
import {state} from '@/core/services/store/auth.module'
import {Capacitor} from '@capacitor/core'
import {App} from '@capacitor/app'

const ApiService = {
    init() {
        Vue.use(VueAxios, axios)
        Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL
    },

    async getTenant() {
        await this.setHeader()
        var data = await ApiService.get('api/App/tenant')
        return data.data
    },
    setHeader() {
        Vue.axios.defaults.headers.common['Authorization'] = `Bearer ${JwtService.getToken()}`

        const isNative = Capacitor.isNativePlatform()

        if (isNative)
            App.getInfo().then((result) => {
                Vue.axios.defaults.headers.common['AppInfo'] = result.name + '-' + result.version
            })
        else
            Vue.axios.defaults.headers.common['AppInfo'] = "Web App"

        Vue.axios.defaults.headers.common['Tenant'] = isNative
            ? localStorage.getItem('TID') ?? 'egitim.istem.k12.tr'
            : window.location.hostname === 'localhost'
                ? 'default'
                : window.location.hostname
    },

    showAlert(message, type = 'warning' ) {
        if (message && message !== '') {
            Swal.fire({
                toast: true,
                position: 'bottom',
                title: message,
                icon: type,
                showConfirmButton: false,
                timer: 3000
            })
        }
    },
    
    showSaveSuccess(data) {
        if (data && data.status && data.status === 200) {
            Swal.fire({
                toast: true,
                position: 'top',
                title: 'Başarılı',
                icon: 'success',
                showConfirmButton: false,
                timer: 2000
            })
            return
        }
    },
    showErrorMessage(text) {
        Swal.fire({
            title: 'Hata',
            html: text,
            icon: 'warning',
            confirmButtonClass: 'btn btn-secondary',
            heightAuto: false,
            confirmButtonText: 'Tamam'
        })
    },
    showError(data) {
        if (data && data.status && data.status === 401) {
            // Swal.fire({
            //   title: 'Hata',
            //   text: 'Lütfen giriş yapınız.',
            //   icon: 'error',
            //   confirmButtonClass: 'btn btn-secondary',
            //   heightAuto: false
            // }).then(() => {
            // window.location.reload(true)
            // })
            return
        } else {
            var errorText = 'Bir hata oluştu. Lütfen tekrar deneyiniz.'

            if (
                data &&
                data.data &&
                data.data.ValidationErrors &&
                data.data.ValidationErrors.length > 0
            ) {
                errorText = ''
                data.data.ValidationErrors.forEach(function (error) {
                    errorText += '<br />' + error.Message
                })
            } else if (data && data.data && data.data.SummaryMessage) {
                errorText = data.data.SummaryMessage
            } else if (data && data.data && data.data.errors) {
                errorText = data.data.errors[Object.getOwnPropertyNames(data.data.errors)[0]][0]
            }
            Swal.fire({
                title: 'Hata',
                html: errorText,
                icon: 'error',
                confirmButtonClass: 'btn btn-secondary',
                heightAuto: false,
                confirmButtonText: 'Tamam'
            })
        }
    },

    query(resource, params) {
        return Vue.axios.get(resource, params)
    },

    /**
     * Send the GET HTTP request
     * @param resource
     * @param slug
     * @returns {*}
     */
    get(resource, slug = '', params) {
        return Vue.axios.get(`${resource}/${slug}`, params)
    },

    /**
     * Set the POST HTTP request
     * @param resource
     * @param params
     * @returns {*}
     */
    post(resource, params, slug = '') {
        return Vue.axios.post(`${resource}/${slug}`, params)
    },

    postwithconfig(resource, params, config) {
        return Vue.axios.post(`${resource}`, params, config)
    },
    /**
     * Send the UPDATE HTTP request
     * @param resource
     * @param slug
     * @param params
     * @returns {IDBRequest<IDBValidKey> | Promise<void>}
     */
    update(resource, slug, params) {
        return Vue.axios.put(`${resource}/${slug}`, params)
    },

    /**
     * Send the PUT HTTP request
     * @param resource
     * @param params
     * @returns {IDBRequest<IDBValidKey> | Promise<void>}
     */
    put(resource, params) {
        return Vue.axios.put(`${resource}`, params)
    },

    /**
     * Send the DELETE HTTP request
     * @param resource
     * @returns {*}
     */
    delete(resource) {
        return Vue.axios.delete(resource)
    },

    getLocalStorage(page, itemName) {
        return localStorage.getItem(page + '_' + state.user.User.User.Id + '_' + itemName)
    },
    setLocalStorage(page, itemName, value) {
        localStorage.setItem(page + '_' + state.user.User.User.Id + '_' + itemName, value)
    }
}

export default ApiService
